import classNames from 'classnames'
import dynamic from 'next/dynamic'

// utils
import { getEllipsisText } from 'utils/string'

const UserBadge = dynamic(() => import('@shared/components/UserBadge'), { ssr: false })

type Props = {
  username: string
  fullName: string | null // some users have fullName is null
  isVerified: boolean
  isProUser?: boolean
  isPremiumUser?: boolean
  className?: string
  maxNameLength?: number // pass a negative number to disable ellipsis
}

const NameWithVerificationBadge = (props: Props) => {
  const { username, fullName, isVerified, isProUser, isPremiumUser, className, maxNameLength = 12 } = props
  const name = getEllipsisText(fullName || '', maxNameLength)
  const isTruncatedWithin1Line = className?.includes('line-clamp-1')

  // -- should show user badge --
  const shouldShowUserBadge = isVerified || isProUser || isPremiumUser

  const VerifiedBadge = (
    <>
      {/* placeholder for the icon */}
      <UserBadge
        className="invisible"
        iconClassName="size-[1em]"
        username={username}
        isVerified={isVerified}
        isProUser={isProUser}
        isPremiumUser={isPremiumUser}
      />
      {/* the display icon */}
      <UserBadge
        iconClassName="absolute right-0 top-1/2 inline size-[1em] -translate-y-1/2"
        username={username}
        isVerified={isVerified}
        isProUser={isProUser}
        isPremiumUser={isPremiumUser}
      />
    </>
  )
  if (isTruncatedWithin1Line) {
    return (
      <p className={classNames(className, 'relative pr-1')}>
        {name}
        {shouldShowUserBadge && VerifiedBadge}
      </p>
    )
  }
  return (
    <p className={classNames(className, 'pr-1')}>
      {name.slice(0, name.length - 1)}
      <span className="relative whitespace-nowrap">
        {/* this prevents the icon being push to next line */}
        {name.slice(name.length - 1, name.length)}&nbsp;
        {shouldShowUserBadge && VerifiedBadge}
      </span>
    </p>
  )
}

export default NameWithVerificationBadge
